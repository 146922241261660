define("auto-counts/app", ["exports", "auto-counts/resolver", "ember-load-initializers", "auto-counts/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      this.modulePrefix = _environment.default.modulePrefix;
      this.podModulePrefix = _environment.default.podModulePrefix;
      this.Resolver = _resolver.default;
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});