define("auto-counts/authenticators/prototype", ["exports", "ember-simple-auth/authenticators/base", "fetch", "md5"], function (_exports, _base, _fetch, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore(data) {
      let hash = data.hash;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)("/auth/".concat(hash, ".json")).then(function (response) {
          return response.json();
        }).then(function (data) {
          data.hash = hash;
          return resolve(data);
        }).catch(function () {
          return reject();
        });
      });
    },

    authenticate(identification, password) {
      let hash = (0, _md.default)("".concat(identification, ":").concat(password));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return (0, _fetch.default)("/auth/".concat(hash, ".json")).then(function (response) {
          return response.json();
        }).then(function (data) {
          data.hash = hash;
          return resolve(data);
        }).catch(function () {
          return reject('check your login details');
        });
      });
    },

    invalidate()
    /*data*/
    {
      return Promise.resolve();
    }

  });

  _exports.default = _default;
});