define("auto-counts/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationRoute extends Ember.Route.extend(_applicationRouteMixin.default) {
    constructor(...args) {
      super(...args);
      this.routeAfterAuthentication = 'dashboard';
    }

  }

  _exports.default = ApplicationRoute;
});