define("auto-counts/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardRoute extends Ember.Route {}

  _exports.default = DashboardRoute;
});