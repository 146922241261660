define("auto-counts/routes/letters/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LettersEditRoute extends Ember.Route {}

  _exports.default = LettersEditRoute;
});