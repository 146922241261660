define("auto-counts/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKB/fUVO",
    "block": "{\"symbols\":[],\"statements\":[[5,\"header-bar\",[],[[\"@isAuthenticated\",\"@invalidateSession\"],[[23,0,[\"session\",\"isAuthenticated\"]],[23,0,[\"invalidateSession\"]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"container mx-auto\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "auto-counts/templates/application.hbs"
    }
  });

  _exports.default = _default;
});