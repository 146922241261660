define("auto-counts/router", ["exports", "auto-counts/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      this.location = _environment.default.locationType;
      this.rootURL = _environment.default.rootURL;
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('authenticated', {
      path: ''
    }, function () {
      this.route('dashboard', {
        path: '',
        resetNamespace: true
      });
      this.route('jobs', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('detail');
      });
    });
    this.route('lists');
    this.route('letters', function () {
      this.route('view');
      this.route('edit');
    });
  });
});